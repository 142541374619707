//
// HOME STYLING
//

@mixin home-common {
  background-size: cover;
  align-self: center;
  position: relative;
  background-position: center center;
}

.home-hero-container {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
  padding: 100px 50px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: $dark;
  position: relative;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw !important;
    height: 100vh !important;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 3 !important;
  }

  .devices {
    position: absolute;
    right: 0;
    bottom: 10%;
    z-index: 1 !important;
    overflow: hidden;

    img {
      width: 100%;
    }
  }

  .hero-btn button {
    min-width: 120px;
    margin: 10px 16px 0 0;
  }

  .features {
    padding: 20px 0;
    font-size: 20px;
    color: #fff;

    p {
      margin: 0;
    }

    i {
      padding-right: 10px;
      color: $success;
    }
  }

  .heading-title {
    > h1 {
      font-size: 64px;
      color: #fff;
    }

    > p {
      margin-top: 8px;
      font-size: 32px;
      color: #fff;
    }
  }
}

.home-slider-container {
  height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);

  .heading-title {
    > h1 {
      font-size: 64px;
      color: #fff;
    }

    > p {
      margin-top: 8px;
      font-size: 32px;
      color: #fff;
    }
  }

  .slider-banner {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    color: white;

    .glide-wrapper {
      flex: 1 1 auto;
      display: flex;
      min-height: 600px;
      height: 80vh;
    }

    .glide {
      display: block;

      .glide__track {
        display: block;
        height: 100%;

        .glide__slides {
          margin: 0;
          height: 100%;

          .glide__slide {
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              background: $dark;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }

      &.glide-dark {
        .glide__arrow {
          color: rgba(black, 0.87);
          border-color: rgba(black, 0.5);
        }
      }
    }

    .content {
      position: relative;
      width: 100%;
      height: 100%;
    }
    .content .centered {
      position: absolute;
      text-align: left;
      padding: 26px 30px;
      max-width: 50%;
      left: 20%;
      top: 40%;

      h1 {
        font-weight: 600;
        margin-top: 0;
      }
    }
  }
}

video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
}

.home-hero-btn {
  margin-top: 32px;
}

.industry-container {
  padding: 100px 0;
  background-color: #f7f7f7;

  .content-section .heading-section {
    padding: 0 100px;
  }
  .content-section .heading-section p {
    font-size: 20px;
  }
}

.heading-section h1 {
  font-size: 34px;
}

.thematic-container {
  padding: 100px 0;
  background-color: #fff;
  color: #fff;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: $dark;
  // background-attachment: fixed;

  .heading-section {
    text-align: center;
  }

  .content-section .thematic-description {
    margin-top: 30px;
  }

  .content-section .thematic-description .theme {
    padding: 0 0.5rem !important;
  }
  .content-section .thematic-description ul {
    padding: 0;
    list-style: none;
    margin: 0 !important;

    i {
      margin-right: 16px;
      color: $success;
    }
  }
}

.sample-container {
  padding: 50px 0;
  background-color: #fff;

  .heading-section {
    text-align: center;
  }

  .content .image-banner {
    height: 400px;
  }

  .content-section {
    .sample-slider {
      width: 100%;
      justify-content: center;
      align-items: center;
      margin-top: 32px;
    }

    .sample-slider .slider-banner {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      color: #000;

      .glide-wrapper {
        flex: 1 1 auto;
        display: flex;
      }

      .glide {
        display: block;

        .glide__track {
          display: block;
          height: 100%;

          .glide__slides {
            margin: 0;
            height: 100%;

            .glide__slide {
              display: flex;
              align-items: center;
              justify-content: center;

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 20%) !important;
                background-color: $dark;
              }
            }
          }
        }

        &.glide-dark {
          .glide__arrow {
            color: rgba(black, 0.87);
            border-color: rgba(black, 0.5);
          }
        }
      }

      .glide__arrow {
        color: #000 !important;
        border: 2px solid rgba(0, 0, 0, 0.5) !important;
      }

      .content {
        width: 100%;
        height: auto;
      }
      .content .image-banner {
        padding-left: 20px;
      }
      .content .centered {
        text-align: left;
        h1 {
          font-weight: 600;
          margin-top: 0;
        }

        .sample-heading {
          padding-left: 10px !important;
        }

        ul {
          list-style: none;

          i {
            margin-right: 12px;
            color: $success;
          }
        }

        button {
          min-width: 100px;
          margin: 16px;
        }
      }
    }

    .sample-slider .heading-title > h1 {
      font-size: 24px;
      color: #000;
    }

    .sample-slider .heading-title > p {
      margin-top: 8px;
      font-size: 16px;
      color: #000;
    }
  }

  .content-section .sample {
    padding: 0 12px;
  }
}

.blog-container {
  padding: 50px 0;
  background-color: #fff;

  .blog-cover {
    min-width: 320px;
    height: 220px;
  }

  .blog-cover img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .blog-heading {
    padding: 16px 5px;
    min-width: 320px;
    min-height: 100px;
  }

  .blog-heading h1 {
    font-size: 20px;
    line-height: 26px;
  }
}

.partner-container {
  padding: 50px 0;
  background-color: #fff;

  .glide__slide img {
    object-fit: contain;
  }
}

@media screen and (min-width: 1440px) {
  .home-hero-container .devices {
    position: absolute;
    bottom: 10%;
    right: 0;
  }
  .sample-container {
    .content .image-banner {
      padding-left: 90px !important;
    }
  }
}
@media screen and (max-width: 1440px) {
  .home-slider-container .slider-banner {
    .content .centered {
      max-width: 70%;
      top: 50%;
      left: 10%;

      h1 {
        font-size: 52px;
        font-weight: 600;
        margin-top: 0;
      }

      p {
        font-size: 28px;
      }
    }
  }

  .sample-container {
    .content .image-banner {
      height: 350px;
    }
  }
}

@media screen and (max-width: 1140px) {
  .home-hero-container .devices {
    img {
      transform: translateX(140px);
    }
  }

  .sample-container {
    .content-section .sample-slider .slider-banner .glide .glide__track .glide__slides .glide__slide {
      align-items: start !important;
    }
    .content .image-banner {
      padding-left: 20px !important;
      height: 350px;
    }
    .sample-slider {
      height: 65vh !important;
      margin: 16px 0 !important;
    }
  }
}

@media screen and (min-width: 768px) {
  .industry-container {
    .heading-section {
      text-align: center;
    }
  }
}

@media screen and (max-width: 768px) {
  .home-hero-container {
    text-align: left;

    .heading-title {
      > h1 {
        font-size: 52px;
      }

      > p {
        font-size: 32px;
      }
    }
  }

  .home-slider-container .slider-banner {
    .content .centered {
      max-width: 100%;
      top: 50%;
      left: 10%;

      h1 {
        font-size: 42px;
        font-weight: 600;
        margin-top: 0;
      }

      p {
        font-size: 20px;
      }
    }
  }

  .industry-container,
  .thematic-container {
    padding: 50px 0;
  }

  .industry-container {
    .content-section .heading-section {
      padding: 0 !important;
    }
    .content-section .heading-section p {
      font-size: 16px;
    }
  }

  .sample-container {
    .heading-section {
      margin-bottom: 20px;
    }
    .sample-slider {
      height: auto !important;
      margin: 0 !important;
    }

    .content .image-banner {
      padding-left: 12px !important;
      height: 400px;
    }

    .content-section .sample {
      padding: 0 12px;

      .heading-title {
        margin-top: 30px;

        h1 {
          font-size: 26px;
        }

        p {
          font-size: 16px;
        }
      }
    }

    .glide__arrow {
      top: 30% !important;
    }
  }
}

@media screen and (max-width: 480px) {
  .home-hero-container {
    .overlay {
      background-color: rgba(0, 0, 0, 0.5);
    }

    .devices {
      bottom: unset;
      top: 40%;
      width: 380px;

      img {
        transform: translateX(120px);
      }
    }
  }

  .home-slider-container .slider-banner .content .centered {
    left: 10%;
    top: 60%;
  }
  .home-hero-container .heading-title > h1 {
    font-size: 32px;
  }

  .home-hero-container .heading-title > p {
    font-size: 20px;
  }

  .home-slider-container .slider-banner {
    .content .centered {
      top: 50%;
      left: 5%;

      h1 {
        font-size: 36px;
        font-weight: 600;
        margin-top: 0;
      }

      p {
        font-size: 18px;
      }
    }
  }

  .thematic-container .heading-section {
    text-align: left;
  }

  .sample-container {
    .heading-section {
      text-align: left;

      .p-jc-center {
        justify-content: start !important;
      }
    }

    .sample-slider {
      height: auto !important;
      margin: 0 !important;
    }

    .content-section .sample-slider .slider-banner .glide .glide__track .glide__slides .glide__slide {
      align-items: start !important;
    }

    .content-section .sample-slider .slider-banner {
      height: auto !important;
    }

    .content .image-banner {
      height: 250px !important;
    }

    .content-section .sample {
      padding: 0 12px;

      .heading-title {
        margin-top: 30px;

        h1 {
          font-size: 26px;
        }

        p {
          font-size: 16px;
          text-align: justify;
        }
      }
    }

    .glide__arrow {
      padding: 5px 6px !important;
    }
  }
  .partner-container {
    padding: 20px 0;
  }
}

@media screen and (max-width: 380px) {
  .home-hero-container {
    padding: 150px 30px 100px;

    .heading-title {
      margin-top: 30px;

      h1 {
        font-size: 30px;
      }

      p {
        font-size: 16px;
        text-align: justify;
      }
    }
  }

  .sample-container {
    .content .image-banner {
      height: 200px !important;
    }
    .sample-slider {
      height: auto !important;
      margin: 0 !important;
    }

    .content-section .sample {
      padding: 0 12px;

      .heading-title {
        margin-top: 30px;

        h1 {
          font-size: 24px;
        }

        p {
          font-size: 14px;
          text-align: justify;
        }
      }
    }

    .glide__arrow {
      top: 15% !important;
    }
  }
}
