@font-face {
  font-family: 'LogoIcon';
  src:  url('fonts/LogoIcon.eot?fc1dcp');
  src:  url('fonts/LogoIcon.eot?fc1dcp#iefix') format('embedded-opentype'),
    url('fonts/LogoIcon.ttf?fc1dcp') format('truetype'),
    url('fonts/LogoIcon.woff?fc1dcp') format('woff'),
    url('fonts/LogoIcon.svg?fc1dcp#LogoIcon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="li-"], [class*=" li-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'LogoIcon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.li-amazon:before {
  content: "\e900";
}
.li-amplify:before {
  content: "\e901";
}
.li-android:before {
  content: "\e902";
}
.li-angular:before {
  content: "\e903";
}
.li-apple:before {
  content: "\e904";
}
.li-apple-appstore:before {
  content: "\e905";
}
.li-bitbucket:before {
  content: "\e906";
}
.li-bitcoin:before {
  content: "\e907";
}
.li-buffer:before {
  content: "\e908";
}
.li-capacitor:before {
  content: "\e909";
}
.li-chrome:before {
  content: "\e90a";
}
.li-closed-captioning:before {
  content: "\e90b";
}
.li-codepen:before {
  content: "\e90c";
}
.li-css3:before {
  content: "\e90d";
}
.li-designernews:before {
  content: "\e90e";
}
.li-dribbble:before {
  content: "\e90f";
}
.li-dropbox:before {
  content: "\e910";
}
.li-edge:before {
  content: "\e911";
}
.li-electron:before {
  content: "\e912";
}
.li-euro:before {
  content: "\e913";
}
.li-facebook:before {
  content: "\e914";
}
.li-firebase:before {
  content: "\e915";
}
.li-firefox:before {
  content: "\e916";
}
.li-flickr:before {
  content: "\e917";
}
.li-foursquare:before {
  content: "\e918";
}
.li-github:before {
  content: "\e919";
}
.li-google:before {
  content: "\e91a";
}
.li-google-playstore:before {
  content: "\e91b";
}
.li-hackernews:before {
  content: "\e91c";
}
.li-html5:before {
  content: "\e91d";
}
.li-instagram:before {
  content: "\e91e";
}
.li-ionic:before {
  content: "\e91f";
}
.li-ionitron:before {
  content: "\e920";
}
.li-javascript:before {
  content: "\e921";
}
.li-laravel:before {
  content: "\e922";
}
.li-linkedin:before {
  content: "\e923";
}
.li-markdown:before {
  content: "\e924";
}
.li-nodejs:before {
  content: "\e925";
}
.li-no-smoking:before {
  content: "\e926";
}
.li-npm:before {
  content: "\e927";
}
.li-octocat:before {
  content: "\e928";
}
.li-pinterest:before {
  content: "\e929";
}
.li-playstation:before {
  content: "\e92a";
}
.li-pwa:before {
  content: "\e92b";
}
.li-python:before {
  content: "\e92c";
}
.li-react:before {
  content: "\e92d";
}
.li-reddit:before {
  content: "\e92e";
}
.li-rss:before {
  content: "\e92f";
}
.li-sass:before {
  content: "\e930";
}
.li-skype:before {
  content: "\e931";
}
.li-slack:before {
  content: "\e932";
}
.li-snapchat:before {
  content: "\e933";
}
.li-stackoverflow:before {
  content: "\e934";
}
.li-steam:before {
  content: "\e935";
}
.li-stencil:before {
  content: "\e936";
}
.li-tumblr:before {
  content: "\e937";
}
.li-tux:before {
  content: "\e938";
}
.li-twitch:before {
  content: "\e939";
}
.li-twitter:before {
  content: "\e93a";
}
.li-usd:before {
  content: "\e93b";
}
.li-vimeo:before {
  content: "\e93c";
}
.li-vk:before {
  content: "\e93d";
}
.li-vue:before {
  content: "\e93e";
}
.li-web-component:before {
  content: "\e93f";
}
.li-whatsapp:before {
  content: "\e940";
}
.li-windows:before {
  content: "\e941";
}
.li-wordpress:before {
  content: "\e942";
}
.li-xbox:before {
  content: "\e943";
}
.li-xing:before {
  content: "\e944";
}
.li-yahoo:before {
  content: "\e945";
}
.li-yen:before {
  content: "\e946";
}
.li-youtube:before {
  content: "\e947";
}
