// FOOTER STYLING

.footer {
  background: $footer;
  padding: 60px 0 30px;
  position: relative;
  color: #fff;

  .footer-content .footer-logo {
    width: 260px;
    max-height: 120px;
  }
  .footer-content .footer-logo img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .footer-content .footer-logo-second {
    width: 200px;
    max-height: 120px;
  }
  .footer-content .footer-logo-second img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .footer-content p {
    font-size: 14px;
  }
  .footer-content h1 {
    font-size: 18px;
    font-weight: 600;
  }

  .footer-content .quicklinks {
    padding-top: 8px !important;
  }

  .footer-content .contacts {
    padding-top: 8px !important;
  }

  .footer-content .downloads {
    padding-top: 8px !important;

    .content .logo {
      width: 120px;
      max-height: 60px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .footer-content .content {
    margin-top: 16px;

    > ul {
      list-style: none;
      padding-left: 0px;

      li {
        font-size: 14px;
        padding: 4px 0;
      }

      a {
        color: #fff;
        font-size: 14px;

        &:hover {
          color: $primary;
        }
      }
    }
  }
}

.bottom-bar {
  background: $footer;
  color: #fff;
  padding-bottom: 64px;

  .copyright {
    padding: 24px 0 !important;
  }

  .copyright p {
    margin: auto 0;
    font-size: 12px;
  }

  .social-media {
    padding: 8px 0 !important;
    justify-content: start;
  }

  @media (min-width: 992px) {
    .social-media {
      padding: 8px 0 !important;
      justify-content: flex-end !important;
    }
  }

  .social-media a {
    width: 40px;
    margin: 0 5px;
  }
  .social-media .p-button i {
    line-height: 1.9rem;
  }
  .social-media .p-button.social {
    background: var(--blue-500);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: background-position 0.5s ease-out;
    color: #fff;
    border-color: none;
    border-radius: 50%;
  }
  .social-media .p-button.social:hover {
    background-position: left bottom;
  }
  .social-media .p-button.social i {
    background-color: var(--blue-500);
  }
  .social-media .p-button.social:focus {
    box-shadow: 0 0 0 1px var(--blue-200);
  }
}
