// Bootstrap
@import '~bootstrap/scss/bootstrap.scss';

// PrimeReact
@import '~primereact/resources/themes/saga-blue/theme.css';
@import '~primereact/resources/primereact.min.css';
@import '~primeicons/primeicons.css';
@import '~primeflex/primeflex.css';

// Glide Slider
@import '~@glidejs/glide/src/assets/sass/glide.core.scss';
@import '~@glidejs/glide/src/assets/sass/glide.theme.scss';

@import './assets/logoicon/style.css';

// Style
@import './assets/scss/style.scss';
@import './assets/scss/custom.scss';

.transition-group {
  position: relative;
}

.route-section {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}

.fade-enter {
  opacity: 0;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-in;
}
