// General

body {
  font-family: $font-family-secondary;
  overflow-x: hidden !important;
  font-size: $font-size-base;
  color: $black;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: $font-family-base;
  line-height: 1.5;
  font-weight: 700;

  a {
    color: #000;
    font-weight: 700;
  }
}
::selection {
  background: rgba($primary, 0.9);
  color: $white;
}

a {
  text-decoration: none !important;
}
p {
  font-family: $font-family-paragraph;
  line-height: 1.6;
}
.p-button {
  font-size: 0.8rem !important;
}

:root {
  --color-facebook: #3b5998;
  --color-github: #24292e;
  --color-instagram: #262626;
  --color-linkedin: #0a66c2;
  --color-twitter: #1da1f2;
  --color-whatsapp: #4caf50;
  --color-youtube: #ff0000;
}
