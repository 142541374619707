// Primereact Custom
.p-dropdown {
  min-width: 200px;
}

.redirect {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

// Other Custom Style
.custom-scrolltop {
  background-color: var(--primary-color) !important;
}
.custom-scrolltop:hover {
  background-color: var(--primary-color) !important;
}

.custom-scrolltop .p-scrolltop-icon {
  font-size: 1rem;
  color: var(--primary-color-text);
}

.hero-container {
  width: 100%;
  height: 60vh;
  min-height: 400px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: $dark;
}
.hero-container .title-heading {
  padding: 150px 50px;
  height: 100%;
  color: #fff;
}
.hero-container .title-heading > h1 {
  font-size: 52px;
}

.hero-container .title-heading > p {
  font-size: 20px;
  padding: 0 50px;
  margin-top: 8px;
}

.main-container {
  padding: 50px 0;
}

i.pi.pi-search {
  right: 1rem;
}

@media (max-width: 768px) {
  .hero-container .title-heading {
    padding: 150px 20px 50px;
    height: 100%;
  }

  .hero-container .title-heading > h1 {
    font-size: 36px;
    font-weight: 600;
  }

  .hero-container .title-heading > p {
    margin-top: 8px;
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .hero-container .title-heading {
    padding: 100px 16px 50px !important;
  }

  .hero-container .title-heading button {
    margin: 5px 8px;
  }
  .hero-container .title-heading > h1 {
    font-size: 30px !important;
  }

  .hero-container .title-heading > p {
    margin-top: 8px;
    font-size: 16px;
    padding: 0 16px;
  }
}

.p-speeddial-action-icon {
  font-size: 1.125rem;
}
