// BLOG STYLING

// Hero
.hero-container {
  .title-heading .meta {
    text-transform: capitalize;
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
  }
}

// Main Page
.main-container {
  background-color: #fff;

  .filter-section {
    .search-container {
      .search span,
      input {
        width: 100%;
      }
    }
  }

  .blog-box {
    padding: 1rem !important;
  }

  .blog-cover {
    position: relative;
    width: 100%;
    height: 220px;

    ul {
      list-style: none;
      display: inline-flex;
      position: absolute;
      top: 8px;
      right: 8px;

      li {
        margin-left: 5px;
      }
    }

    .btn-label-category {
      padding: 3px 8px;
      background-color: var(--bluegray-500);
      border-radius: 5px;
      min-width: 80px;
      text-align: center;
      color: #fff;
    }

    .label-category {
      text-transform: capitalize;
      font-size: 14px;
      font-weight: 600;
    }
  }

  .blog-cover img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-color: $dark;
  }

  .blog-heading {
    padding: 16px 5px;
    min-height: 100px;
  }

  .blog-heading h1 {
    font-size: 22px;
    line-height: 26px;
  }

  .meta {
    text-transform: capitalize;
    font-size: 16px;
    line-height: 20px;
    font-weight: 300;

    ul {
      padding-left: 5px;
      list-style: none;
      display: inline-flex;
      text-transform: capitalize;

      li {
        margin-left: 5px;
      }
    }
  }

  .blog-heading .description {
    margin-top: 0.5em;
    .body {
      max-height: 76px;
      overflow: hidden;
    }

    .body p {
      text-indent: 0 !important;
      -webkit-line-clamp: 4;
    }

    .body p span {
      padding: 0 5px 0 0;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box !important;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      white-space: normal;
    }
  }

  // Details
  .left-content .heading h1 {
    font-size: 34px;
  }

  .left-content .featured-image {
    width: 100%;
    height: 400px;
    padding: 16px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .right-content ul {
    list-style: none;
  }

  .right-content .blogs {
    .thumbnail-blog {
      width: 80px;
      height: 80px;
      border-radius: 5px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 5px;
    }

    .heading-blogs {
      padding: 0 5px;

      h1 {
        font-size: 16px;
      }

      .body {
        max-height: 50px;
        overflow: hidden;
      }

      .body p {
        text-indent: 0 !important;
        -webkit-line-clamp: 4;
        margin: 0 !important;
        text-align: left;
      }

      .body p span {
        padding: 0;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box !important;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: normal;
        text-align: left;
      }
    }
  }
}
@media screen and (max-width: 1140px) {
  .main-container .right-content .blogs {
    .thumbnail-blog {
      width: 65px;
      height: 65px;
      border-radius: 5px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 5px;
    }

    .heading-blogs {
      padding: 0 5px 0 10px;

      h1 {
        font-size: 14px;
      }

      .body {
        display: none;
        max-height: 50px;
        overflow: hidden;
      }
    }
  }
}
@media screen and (max-width: 991px) {
  .main-container .right-content {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .main-container .blog-cover {
    height: 200px !important;
  }
  .main-container .left-content .featured-image {
    width: 100%;
    height: 300px !important;
    padding: 8px !important;
  }

  .main-container .left-content p img {
    width: -webkit-fill-available;
  }
}

@media screen and (max-width: 480px) {
  .main-container .left-content .heading h1 {
    font-size: 22px;
  }

  .main-container .left-content .featured-image {
    width: 100%;
    height: 200px !important;
    padding: 5px !important;
  }

  .main-container .meta {
    font-size: 12px;
  }
}
